import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

import history from './history';

export default class Routes extends Component {

    Login () {
        return (
            <>
            <div>
                <AmplifyAuthenticator />
            </div>
            </>
        )
    }
    
    Content () {
        return (
            <>
                <p>
                    Nothing
                </p>
            </>
        )
    }

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={this.Content} />
                    <Route path="/faq" component={this.Content} />
                    <Route path="/realm" component={this.Content} />
                    <Route path="/leaderboard" component={this.Content} />

                    <Route path="/inventory" component={this.Content} />
                    <Route path="/skills" component={this.Content} />
                    <Route path="/achievements" component={this.Content} />

                    <Route path="/teams" component={this.Content} />

                    <Route path="/login" component={this.Login} />
                    <Route path="/logout" component={this.Content} />
                </Switch>
            </Router>
        )
    }
}