import React from 'react';
import './App.less';
import Amplify from 'aws-amplify';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import history from './history';
import { Button } from 'antd';

Amplify.configure(awsconfig);

const AuthStateApp: React.FunctionComponent = () => {
    const [authState, setAuthState] = React.useState<AuthState>();
    const [user, setUser] = React.useState<any | undefined>();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

  return authState === AuthState.SignedIn && user ? (
      <div>
          <div>Hello, {user.username}</div>
          <div>
            <AmplifySignOut />
          </div>
      </div>
  ) : (
      <span className="center">
        <Button danger className="center" onClick={() => history.push('/login')}>
            ANON - Create/Login
        </Button>
      </span>
  );
}

export default AuthStateApp;