import React from 'react'
import { Button, Layout, Space, Typography } from 'antd';

import AppMenu from './AppMenu'
import AuthStateApp from './AuthStateApp';
import ClientClock from './ClientClock';
import Routes from './Routes';

import history from './history';

import './App.less';

const { Header, Content, Footer, Sider } = Layout;

export default class App extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed:boolean) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className="logo" />
          <AppMenu />
          <Space align="center" direction="vertical">
            <AuthStateApp />
          </Space>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Space size={30} align="baseline">
              <Space>

              </Space>
              <Button type="primary" size="large" onClick={() => {history.push('/')}}>
                Idle-Adventures
              </Button>
              <Space>
                <Typography>
                  <ClientClock/>
                </Typography>
              </Space>
            </Space>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Routes />
          </Content>
          <Footer style={{ textAlign: 'center' }}>Idle-Adventures ©2020 Created by one developer.</Footer>
        </Layout>
      </Layout>
    );
  }
}
