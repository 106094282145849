/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9a0ff0e0-c65a-4ed9-8246-4e7a8c82bcfe",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IXQw8Wis8",
    "aws_user_pools_web_client_id": "3l9p9pn4p8hcpn9r58ap1n3t4r",
    "oauth": {}
};


export default awsmobile;
