import React from 'react';

interface ClientClockState {
    date: String,
    time: String
}

export default class ClientClock extends React.Component<{}, ClientClockState> {
    timerID: any;

    constructor(props:any) {
        super(props)

        const now = new Date()
        this.state = {
            date: now.toLocaleDateString(),
            time: now.toLocaleTimeString()
        }
    }
    
    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }
  
    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    tick() {
        const now = new Date()
        this.setState({
            date: now.toLocaleDateString(),
            time: now.toLocaleTimeString()
        })
    }

    render() {
        return(
            <div>
                {this.state.date} {this.state.time}
            </div>
        )
    }
}
