import React from 'react'
import { Avatar, Menu, Typography } from 'antd';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import {
  HomeFilled,
  GlobalOutlined,
  QuestionCircleFilled,
  TrophyFilled,
  CloseCircleFilled,
  ContainerFilled,
  ExperimentFilled,
  FlagFilled,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import history from './history'

Amplify.configure(awsconfig);

const { SubMenu } = Menu;
const { Text } = Typography;

interface AuthState {
  loggedIn: boolean
}

export default class AppMenu extends React.Component<{}, AuthState> {
  constructor(props: any) {
    super(props)

    this.state = {
      loggedIn: false
    }

    Hub.listen('auth', (data) => {
      const { payload } = data
      console.log('A new auth event has happened: ', data)
      if (payload.event === 'signIn') {
        console.log('a user has signed in!')
        this.setState({
          loggedIn: true
        })
      }
      if (payload.event === 'signOut') {
        console.log('a user has signed out!')
        this.setState({
          loggedIn: false
        })
      }
    })

    this.checkUser()
  }

  checkUser(): void {
    Auth.currentAuthenticatedUser()
      .then(user => { console.log(user); this.setState({ loggedIn: true }) })
      .catch(err => { console.log(err); this.setState({ loggedIn: false }) })
  }

  render() {
    return (
      <>
        <div hidden={!this.state.loggedIn}>
          <Avatar shape="square" size={64} icon={<UserOutlined />} />
          <Text keyboard>Character Name</Text>
        </div>
        <Menu id="mainNavMenu" mode="inline">
          <Menu.Item icon={<HomeFilled />} className="Menu" onClick={() => history.push('/')}>
            Home
            </Menu.Item>
          <Menu.Item icon={<QuestionCircleFilled />} onClick={() => history.push('/faq')}>
            FAQ
            </Menu.Item>
          <Menu.Item icon={<GlobalOutlined />} onClick={() => history.push('/realm')}>
            Realm
            </Menu.Item>
          <Menu.Item icon={<TrophyFilled />} onClick={() => history.push('/leaderboard')}>
            Leaders
          </Menu.Item>
        </Menu>
        <div hidden={!this.state.loggedIn}>
          <Menu id="loggedInMenu" mode="inline">
            <SubMenu icon={<UserOutlined />} title="Player" disabled={!this.state.loggedIn}>
              <Menu.Item icon={<ContainerFilled />} onClick={() => history.push('/inventory')} disabled={!this.state.loggedIn}>Inventory</Menu.Item>
              <Menu.Item icon={<ExperimentFilled />} onClick={() => history.push('/skills')} disabled={!this.state.loggedIn}>Skills</Menu.Item>
              <Menu.Item icon={<FlagFilled />} onClick={() => history.push('/achievements')} disabled={!this.state.loggedIn}>Achievements</Menu.Item>
            </SubMenu>
            <Menu.Item icon={<TeamOutlined />} title="Teams" onClick={() => history.push('/teams')} disabled={!this.state.loggedIn}>Teams</Menu.Item>
            <Menu.Item icon={<CloseCircleFilled />} onClick={() => history.push('/quit')} disabled={!this.state.loggedIn}>Quit</Menu.Item>
          </Menu>
        </div>
      </>
    )
  }
}